import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/pageheader"
import * as styles from "../../styles/access.module.css"
import hero from "../../images/access-hero.jpg"
import image from "../../images/toll-road.jpg"

const ServicesSinglePage = () => (
  <Layout activeMenuId={6}>
    <Seo title="Service Name" />
    <PageHeader
      title="Service Name"
      subTitle={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam neque nisi, hendrerit in augue vitae, mollis ornare justo. Etiam et risus at quam interdum porta. Nam malesuada dui ac auctor placerat.`}
      image={hero}
      breadcrumbs={[
        { label: "Services", link: "/services" },
        { label: "Service Name", link: null },
      ]}
    />
    <div className="container mx-auto">
      <div className="text-center">
        <div><img src={image} alt="Toll Road" /></div>
      </div>
      <div className={`px-4 ${styles.text}`}>
        <h2>The Title Here</h2>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
        <p>Nunc porta vulputate tellus.</p>
        <p>
          Pellentesque dapibus suscipit ligula. Donec posuere augue in quam.
          Etiam vel tortor sodales tellus ultricies commodo. Suspendisse
          potenti. Aenean in sem ac leo mollis blandit. Donec neque quam,
          dignissim in, mollis nec, sagittis eu, wisi. Phasellus lacus. Etiam
          laoreet quam sed arcu. Phasellus at dui in ligula mollis ultricies.
          Integer placerat tristique nisl. Praesent augue. Fusce commodo.
          Vestibulum convallis, lorem a tempus semper, dui dui euismod elit,
          vitae placerat urna tortor vitae lacus. Nullam libero mauris,
          consequat quis, varius et, dictum id, arcu. Mauris mollis tincidunt
          felis. Aliquam feugiat tellus ut neque. Nulla facilisis, risus a
          rhoncus fermentum, tellus tellus lacinia purus, et dictum nunc justo
          sit amet elit.
        </p>
      </div>
    </div>
  </Layout>
)

export default ServicesSinglePage
